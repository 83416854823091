import React, { useEffect, useState } from 'react'

function Page(props) {

  const [params, setParams] = useState()

  useEffect(() => {
    setParams(props?.location?.search?.split('?')[1])
  }, [])

  // Split params
  if (params && params !== '') {
    var search = params.split('&').reduce(function (res, item) {
      var parts = item.split('=')
      res[parts[0]] = parts[1]
      return res;
    }, {})
  }

  useEffect(() => {

    const pixel = document?.querySelector('body > img')
    if (pixel)
      pixel.style.display = "none"

    const mimic = document?.querySelector('#master-header-mimic')
    if (mimic)
      mimic.style.display = "none"

    const html = document?.querySelector('html')
    if (html)
      html.style.overflow = "hidden"

  }, [])

  if (search?.url?.startsWith("rkhnw24d6im5") || search?.url?.startsWith("71tzd15sgbj0") || search?.url?.startsWith("wujhxfu8hx6p")) {

    //Remove trailing slash in Contentful URL
    let url = search?.url?.slice(-1) === '/' ? search?.url?.slice(0, -1) : search?.url

    return (
      (typeof window !== 'undefined' && window.innerWidth <= 1024) ?
        <iframe
          src={`https://docs.google.com/gview?url=https://assets.ctfassets.net/${url}&embedded=true`}
          style={{ height: "100vh" }}
        />
        :
        <object
          data={`https://assets.ctfassets.net/${url}`}
          type="application/pdf"
          width="100%"
          style={{ height: '100vh' }}
        >
          <a href={`https://assets.ctfassets.net/${url}`}>Open File</a>
        </object>
    )
  }
  else
    return null
}

export default Page
